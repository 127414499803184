import { PaginationInterface } from '../interfaces/pagination-interface';
import { EUpdateTournee } from '../utils';
import { PointOfSale } from './point-of-sale';
import { Sector } from './sector';
import { Tournee } from './tournee';
import { User } from './user';
import { ZoneSupervisor } from './zone-supervisor';
export class SalesToursPaginationInterface implements PaginationInterface {
  page: number = 1;
  limit: number = 15;
  sector_ids?: number[];
  seller_ids?: number[];
  zone_supervisor_ids?: number[];
  date?: string;
  next?: number | null;
  items?: number | undefined;
  last?: number | null;
  pages?: number | null;
  status?: string;
}

export interface IItemTourneeUpdate {
  product_id: number;
  quantity: number;
  product_sku_sage: string;
  updated_at: string;
}
export interface ITournees {
  tournee?: ISalesTours;
  sales?: ISales[];
  unsolds?: IUnsolds[];
  loadings?: ILoadingsProducts[];
  unloadings?: IUnloadingsProducts[];
  return_products?: IReturnProducts[];
  movement_of_stocks: IMovementOfStocks[];
  additional_loadings?: IAdditionalLoadings[];
}

export interface IMovementOfStocks {
  origin: IOrigin;
  recipient: IOrigin;
  sector: ISector;
  date: Date;
  direction?: string;
  created_at: string;
  updated_at: string;
  lettering?: string;
  sender_tournee?: Tournee;
  recipient_tournee?: Tournee;
  sender_sector: Sector;
  recipient_sector: Sector;
  status?: EMovementOfStockStatus;
  movement_of_stock_items: IMovementOfStocksItems[];
}

export interface IMovementOfStocksItems {
  id: number;
  quantity: number;
  product_sku_sage: string;
  product_is_accessorie: boolean;
  product_is_accessorie_color?: string;
  product_is_accessorie_text?: string;
  created_at: string;
}

export interface IOrigin {
  id: number;
  username: string;
  email: string;
  qr_code: string;
  phone: string;
}

export interface IUnloadingsProducts {
  id: number;
  quantity_pierced: number;
  quantity_pierced_chef: number;
  quantity_declass: number;
  quantity_declass_chef: number;
  quantity_claim: number;
  quantity_claim_chef: number;
  quantity_not_sale: number;
  quantity_not_sale_chef: number;
  quantity_missing_chef: number;
  quantity_missing: number;
  product_id: number;
  product_sku_sage: string;
  product_sku_commcare: string;
  product_is_accessorie: boolean;
  product_is_accessorie_color?: string;
  product_is_accessorie_text?: string;
  date: string;
  created_at: string;
  updated_at: string;
  shift_supervisor: any;
}

export interface IAdditionalLoadings {
  id: number;
  chef_quart: User;
  sector: Sector;
  user: User;
  date: string;
  status_chef: string;
  status_seller: string;
  additionnal_loading_items: IAdditionalLoadingsItems[];
}

export interface IAdditionalLoadingsItems {
  id: number;
  quantity: number;
  product_sku_sage: string;
  product_is_accessorie_color?: string;
  product_is_accessorie_text?: string;
  date: string;
  created_at: string;
}
export interface ILoadingsProducts {
  id: number;
  asked: string;
  loaded: string;
  product_id: number;
  product_sku_sage: string;
  product_sku_commcare: string;
  product_is_accessorie: boolean;
  product_is_accessorie_color?: string;
  product_is_accessorie_text?: string;
  date: string;
  created_at: string;
  updated_at: string;
}
export interface ISalesTours {
  status: string;
  seller: User;
  sector: Sector;
  shift_supervisor?: User;
  supervisor?: User;
  zone_supervisor: ZoneSupervisor;
  date: string;
  statusColor?: string;
  ca?: number;
}

export interface ISales {
  id: number;
  tournee_id: number;
  point_of_sale_id: number;
  date: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  quantity: number;
  total_sales: number;
  sale_products_items: ISaleProductsItems[];
  point_of_sale: PointOfSale;
  price_total: number;
  paiementmode: IPaiementMode;
  statusColorPM?: string;
  paiement_mode: string;
}

export interface IPaiementMode {
  id: number;
  label: string;
}

export interface ISaleProductsItems {
  id: number;
  product_sku_sage: string;
  quantity: number;
  price_total: number;
}

export interface IUnsolds {
  id: number;
  tournee_id: number;
  point_of_sale_id: number;
  sale_id: number;
  date: string;
  quantity: number;
  price_total: number;
  price: number;
  product_id: number;
}
export interface IReturnProducts {
  id: number;
  point_of_sale_id: number;
  sale_id: number;
  return_type: string;
  quantity: number;
  price: number;
  product_sku_sage: string;
  date: string;
  return_type_detail: string;
  product_id: number;
}
export interface ISeller {
  username: string;
  email: string;
  status: string;
  phone: string;
}

export interface ISector {
  label: string;
  description: string;
  status: string;
  zone_supervisor_id: number;
}

export interface IZoneSupervisor {
  label: string;
}

export enum ESalesToursStatus {
  New = 'New',
  In_Progress = 'In Progress',
  Finish = 'Finish',
}

export enum EMovementOfStockStatus {
  INITIATED = 'INITIATED',
  VALIDATED = 'VALIDATED',
  CANCELED = 'CANCELED',
  VALIDATED_BY_RECIPIENT = 'VALIDATED_BY_RECIPIENT',
}

export enum EMovementOfStockStatusFR {
  INITIATED = 'Inité',
  VALIDATED = 'Effectué',
  CANCELED = 'Annulé',
  VALIDATED_BY_RECIPIENT = 'En attente',
}

//Update Tournee Items
export interface IUpdateTourneeLoadingEmitter {
  valuesToUpdate: any[];
  itemTournee: EUpdateTournee;
}

export interface EditLoadingTourneeDTO {
  tournee_id: number;
  loadings: ILoadingsQuantity[];
}

export interface ILoadingsQuantity {
  id: number;
  product_id: number;
  quantity: number;
}

export interface EditAdditionalLoadingTourneeDTO {
  tournee_id: number;
  additionnal_loading_items: ILoadingsQuantity[];
}

export interface EditStockMovementTourneeDTO {
  tournee_id: number;
  movement_of_stock_items: ILoadingsQuantity[];
}

export interface EditUnloadingTourneeDTO {
  tournee_id: number;
  unloadings: IUnloadingsQuantity[];
}

export interface IUnloadingsQuantity {
  id: number;
  product_id: number;
  quantity_pierced?: number;
  quantity_not_sale?: number;
  quantity_missing?: number;
  quantity_declass?: number;
  quantity_claim?: number;
}

export interface IRouteListTourneeItem {
  id: number;
  name: string;
  qr_code: string;
  phone: string;
  address: string;
  golden_shop: boolean;
  secours: boolean;
  lat: string;
  lng: string;
  status: string;
  zone_label: string;
  sales: number;
  no_sales: number;
  last_visited_at: string;
  isExceptedVisit: boolean;
}

export interface ITourneeItemsMenu {
  key: SectionKey;
  label: string;
  icon: string;
  color: string;
  toggleFunction: () => void;
  showFlag: boolean;
  data: any[];
}

export type SectionKey =
  | 'chargement'
  | 'chargementSupplementaire'
  | 'ventes'
  | 'dechargement'
  | 'inventoryMovement'
  | 'unloadingBill'
  | 'routeList';

export const ITEM_TOURNEE_VISIBILITY: Record<SectionKey, boolean> = {
  chargement: false,
  chargementSupplementaire: false,
  ventes: false,
  dechargement: false,
  inventoryMovement: false,
  unloadingBill: false,
  routeList: false,
};