import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShareAttributeService {
  dateFilter!: Date;
  constructor() {}

  setDateFilterValue(date: Date) {
    this.dateFilter = date;
  }

  get dateFilterValue() {
    return this.dateFilter;
  }
}
