import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { getPreviousURL, setPreviousURL } from 'src/app/utils/helpers';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss'],
})
export class GoBackComponent implements OnInit {
  private previousUrl: string | null = null;

  @Input() marginClass: string = '';
  @Input() redirectUrl: string = '';
  constructor(private location: Location, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        setPreviousURL(this.router.url);
      }
    });
  }

  ngOnInit(): void {
    this.previousUrl = getPreviousURL();
  }

  back(): void {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
    } else if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.location.back();
    }
  }
}
