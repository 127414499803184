import { HttpClient } from '@angular/common/http';
import Config from '../utils/config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegionCommercialService {
  constructor(private http: HttpClient) {}

  getAllRegionCommercials() {
    return this.http.get<any>(`${Config.baseUrl}/region_commercials/`);
  }

  getAllRegionCommercialsV2() {
    return this.http.get<any>(`${Config.baseUrlV2}/region_commercials/`);
  }
}
