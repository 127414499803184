import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import Config from '../utils/config';
import { NewProductDTO, ProductListDTO, ProductPaginationDTO, UpdateProductDTO } from '../dtos/product.dto';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  getProducts(): Observable<Product[]> {
    return this.http.get<[]>(`${Config.baseUrl}/mt/product`);
  }

  getAllProducts() {
    return this.http.get<any>(`${Config.baseUrlV2}/products`);
  }
    
  getListProducts(payload: ProductPaginationDTO): Observable<ProductListDTO> {
    return this.http.post<ProductListDTO>(
      `${Config.baseUrlV2}/products/list`,
      payload
    );
  }
  
  addProduct(payload: NewProductDTO) {
    return this.http.post<any>(`${Config.baseUrlV2}/products`, payload);
  }

  getProductWhitoutDuplicationOfCountry(country_id: number){
    return this.http.get<[]>(`${Config.baseUrl}/get_products_by_country_without_duplication/${country_id}`);
  }

  updateProduct(payload: UpdateProductDTO, id: number) {
    return this.http.put<any>(`${Config.baseUrlV2}/products/${id}`, payload);
  }
}
