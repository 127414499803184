import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoadingsComponent } from './pages/loadings/loadings.component';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from './services/authentication-guard.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RoleGuard } from './guards/role.guard';
import { TransactionsForBillingComponent } from './pages/transactions/transactions-for-billing/transactions-for-billing.component';
import { AccountingEntriesComponent } from './pages/transactions/accounting-entries/accounting-entries.component';
import { EUserRole } from './utils';
import { FirstLoginPasswordChangeComponent } from './pages/first-login-password-change/first-login-password-change.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuardService],
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthenticationGuardService],
      },
      {
        path: 'loadings',
        component: LoadingsComponent,
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'Précommandeur'],
        },
      },
      {
        path: 'inventories',
        loadChildren: () =>
          import('./pages/inventories/inventories.module').then(
            (m) => m.InventoriesModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: [
            EUserRole.ADMIN,
            EUserRole.SHIFT_SUPERVISOR,
            EUserRole.COUNTRY_MANAGER,
            EUserRole.SUPERVIOR_LOGISTIC,
            EUserRole.PLANNER,
          ],
        },
      },
      {
        path: 'point-of-sales',
        loadChildren: () =>
          import('./pages/point-of-sales/point-of-sales.module').then(
            (m) => m.PointOfSalesModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'Précommandeur'],
        },
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./pages/sales/sales.module').then((m) => m.SalesModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: [
            'admin',
            'superviseur',
            'Business Developper',
            'Précommandeur',
          ],
        },
      },
      {
        path: 'gestion-of-trucks',
        loadChildren: () =>
          import('./pages/gestion-of-vehicle/gestion-of-vehicle.module').then(
            (m) => m.GestionOfVehicleModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: [
            EUserRole.ADMIN,
            EUserRole.LOGISTIC_MANAGER,
            EUserRole.PLANNER,
          ],
        },
      },
      {
        path: 'trade-schedules',
        loadChildren: () =>
          import('./pages/trade-schedules/trade-schedules.module').then(
            (m) => m.TradeSchedulesModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'Précommandeur'],
        },
      },
      {
        path: 'forecasts',
        loadChildren: () =>
          import('./pages/forecast/forecast.module').then(
            (m) => m.ForecastModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'superviseur', 'chef de zone'],
        },
      },
      {
        path: 'others',
        loadChildren: () =>
          import('./pages/others/others.module').then((m) => m.OthersModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'superviseur', 'chef de zone'],
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./pages/roles/roles.module').then((m) => m.RolesModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'permissions',
        loadChildren: () =>
          import('./pages/permissions/permissions.module').then(
            (m) => m.PermissionsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'request-loadings',
        loadChildren: () =>
          import('./pages/request-loadings/request-loadings.module').then(
            (m) => m.RequestLoadingsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'superviseur', 'chef de quart'],
        },
      },
      {
        path: 'point_of_sales_tradi',
        loadChildren: () =>
          import(
            './pages/point-of-sales-tradi/point-of-sales-tradi.module'
          ).then((m) => m.PointOfSalesTradiModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'superviseur', 'Business Developper'],
        },
      },
      {
        path: 'stock-controls',
        loadChildren: () =>
          import('./pages/stock-controls/stock-controls.module').then(
            (m) => m.StockControlsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'chef de quart'],
        },
      },
      {
        path: 'stores-checks',
        loadChildren: () =>
          import('./pages/stores-checks/stores-checks.module').then(
            (m) => m.StoresChecksModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: [EUserRole.ADMIN, EUserRole.SUPERVISOR],
        },
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./pages/transactions/transactions.module').then(
            (m) => m.TransactionsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'responsable treso', 'DAF'],
        },
      },
      {
        path: 'seller-references',
        loadChildren: () =>
          import('./pages/seller-references/seller-references.module').then(
            (m) => m.SellerReferencesModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'responsable treso', 'DAF'],
        },
      },
      {
        path: 'accounting-entries',
        component: AccountingEntriesComponent,
      },
      {
        path: 'billing-transactions',
        component: TransactionsForBillingComponent,
      },
      {
        path: 'depots',
        loadChildren: () =>
          import('./pages/depots/depots.module').then((m) => m.DepotsModule),
      },
      {
        path: 'sectors',
        loadChildren: () =>
          import('./pages/sectors/sectors.module').then((m) => m.SectorsModule),
      },
      {
        path: 'clusters',
        loadChildren: () =>
          import('./pages/clusters/clusters.module').then(
            (m) => m.ClustersModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'clusters-rtm',
        loadChildren: () =>
          import('./pages/clusters-rtm/clusters-rtm.module').then(
            (m) => m.ClustersRtmModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./pages/products/products.module').then(
            (m) => m.ProductsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('./pages/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'tour-management',
        loadChildren: () =>
          import('./pages/tours/tours-routing.module').then(
            (m) => m.ToursRoutingModule
          ),
      },
      {
        path: 'stock-movements',
        loadChildren: () =>
          import('./pages/stock-movements/stock-movements.module').then(
            (m) => m.StockMovementsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'chef de quart'],
        },
      },
      {
        path: 'sage-synchronizations',
        loadChildren: () =>
          import(
            './pages/sage-synchronizations/sage-synchronizations.module'
          ).then((m) => m.SageSynchronizationsModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'chef de quart'],
        },
      },
      {
        path: 'catalogs',
        loadChildren: () =>
          import('./pages/catalogs/catalogs.module').then(
            (m) => m.CatalogsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'tournees',
        loadChildren: () =>
          import('./pages/tournee/tournee.module').then((m) => m.TourneeModule),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin', 'superviseur'],
        },
      },
      {
        path: 'unloading-bills',
        loadChildren: () =>
          import('./pages/unloading-bills/unloading-bills.module').then(
            (m) => m.UnloadingBillsModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: [EUserRole.ADMIN, EUserRole.SHIFT_SUPERVISOR],
        },
      },
      {
        path: 'countries',
        loadChildren: () =>
          import('./pages/countries/countries.module').then(
            (m) => m.CountriesModule
          ),
        canActivate: [AuthenticationGuardService, RoleGuard],
        data: {
          roles: ['admin'],
        },
      },
      {
        path: 'zones',
        loadChildren: () =>
          import('./pages/zones/zones.module').then((m) => m.ZonesModule),
      },
      {
        path: 'zone-supervisors',
        loadChildren: () =>
          import('./pages/zone-supervisors/zone-supervisors.module').then(
            (m) => m.ZoneSupervisorsModule
          ),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import('./pages/survey/survey.module').then((m) => m.SurveyModule),
      },
      {
        path: 'payment-modes',
        loadChildren: () =>
          import('./pages/payment-modes/payment-modes.module').then(
            (m) => m.PaymentModesModule
          ),
      },
      {
        path: 'commercial-regions',
        loadChildren: () =>
          import('./pages/commercial-regions/commercial-regions.module').then(
            (m) => m.CommercialRegionsModule
          ),
      },

    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'first-login',
    component: FirstLoginPasswordChangeComponent,
  },
  {
    path: 'maps',
    loadChildren: () =>
      import('./pages/maps/maps.module').then((m) => m.MapsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
