import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-first-login-password-change',
  templateUrl: './first-login-password-change.component.html',
  styleUrls: ['./first-login-password-change.component.scss']
})
export class FirstLoginPasswordChangeComponent implements OnInit {
  form: UntypedFormGroup;
  message: string = '';
  loading: boolean = false;
  token: string = '';
  strongPassword: boolean = false;
  currentUser!: User;
  showPassword: boolean = false;


  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
      },
    );
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  ngOnInit(): void { 
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.is_default_password_changed) {
      this.router.navigateByUrl('/');
    }
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }
  
  onSubmit() {
    const val = this.form.value;
    this.loading = true;
    this.authenticationService
      .resetDefaultPassword(this.currentUser.id!, {...val})
      .subscribe({
        next: (response) => {
          if (response) {
            this.message = 'Votre mot de passe a été changé avec succès';
            this.router.navigate(['/']);
          }
        
        },
        error: (error: any) => {
          this.message = error;
          this.loading = false;
        },
      });
  }
}
