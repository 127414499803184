<div class="table-responsive">
  <table *ngIf="tableData.length" class="table align-middle table-hover">
    <thead [ngStyle]="{'background-color': tableHeadBgColor || null}" [ngClass]="{'thead-position': tableHeigh.length}"
      class="table-light">
      <tr class="rounded" [ngStyle]="{'background-color': tableHeadBgColor || null}" >
        <th [ngStyle]="{'background-color': tableHeadBgColor || null}" scope="col" *ngFor="let column of columnDefs"
          [class]="column.hearderPositionClass">
          {{ column.label }}
        </th>
      </tr>
    </thead>
    <tbody [ngStyle]="{'height': tableHeigh || null}" [ngClass]="{'tbody-scroll': tableHeigh.length}">
        <tr [ngClass]="{'tr-position': tableHeigh.length}" *ngFor="let row of tableData">
          <td *ngFor="let column of columnDefs" [ngClass]="[getCellClass(column)]">
            <app-cell [column]="column" [rowData]="row" (actionInvoqueFunctionEvent)="invoqueActionsFunction($event)"
              (onClickCellValueEvent)="clickCellValueEvent($event)"></app-cell>
          </td>
        </tr>
    </tbody>
  </table>
</div>