<ng-template #modal>
  <div class="modal-header {{ modalConfig.headerClass }}">
    <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
    <ng-content select="[test]"></ng-content>
    <ng-content select="selector"></ng-content>
  </div>
  <div class="modal-body" [style]="modalConfig.bodyClass">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer" [style]="modalConfig.footerClass">
    <div>
      <ng-content select="[error]"></ng-content>
    </div>

    <ng-content select="[buttons]"></ng-content>

    <button
      type="button"
      class="btn {{ modalConfig.cancelButtonClass }}"
      (click)="cancel()"
      *ngIf="
        modalConfig.hideCancelButton === undefined ||
        !modalConfig.hideCancelButton()
      "
      [disabled]="
        modalConfig.disableCancelButton !== undefined &&
        modalConfig.disableCancelButton()
      "
    >
      {{ modalConfig.cancelButtonLabel }}
    </button>
    <button
      ngbAutofocus
      type="button"
      class="btn {{ modalConfig.submitButtonClass }}"
      (click)="submit()"
      *ngIf="
        modalConfig.hideSubmitButton === undefined ||
        !modalConfig.hideSubmitButton()
      "
      [disabled]="
        modalConfig.disableSubmitButton !== undefined &&
        modalConfig.disableSubmitButton()
      "
    >
      {{ modalConfig.submitButtonLabel }}
    </button>
  </div>
</ng-template>
