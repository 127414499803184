<div class="content-page text-center">
    <div class="form-reset">
        <div class="text-info">
            <h3>Leuk Back-office</h3>
        </div>
        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="message !== ''">
            {{message}}
        </ngb-alert>
        <div class="form-reset-content">
            <div class="card">
                <h5 class="card-header">Veuillez choisir un nouveau mot de passe</h5>
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-floating mb-2">
                            <input formControlName="password" type="{{showPassword? 'text': 'password'}}"
                                class="form-control" id="password" placeholder="name@example.com" />
                            <label for="password">Mot de passe</label>
                        </div>
                        <app-password-strength [passwordToCheck]="form.value.password"
                            (passwordStrength)="onPasswordStrengthChanged($event)"></app-password-strength>
                        <div *ngIf="loading" class="spinner-border text-info mt-5" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="form-check d-flex align-items-center my-4">
                            <input class="form-check-input " (click)="togglePassword()" type="checkbox" value="showPassword"
                                id="flexCheckDefault">
                            <label class="form-check-label ms-2" for="flexCheckDefault">
                                Afficher le mot de passe
                            </label>
                        </div>
                        <button [disabled]="!form.valid || loading" *ngIf="!loading"
                            class="mt-3 w-100 btn btn-lg btn-primary text-white" type="submit">
                            Réinitialiser
                        </button>
                    </form>
                </div>
            </div>
            <p class="mt-5 mb-3 text-muted">&copy; 2022 - Laiterie du berger</p>
        </div>
    </div>
</div>