import { User } from '../models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable, shareReplay, tap } from 'rxjs';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${Config.baseUrlV2}/token`, { username, password })
      .pipe(
        tap((user) => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })   
      );
  }

  forgot(email: string) {
    return this.http.post<any>(`${Config.baseUrl}/forgot-password`, { email });
  }

  resetDefaultPassword(id: number, value: {password: string}) {
    return this.http.post<User>(`${Config.baseUrlV2}/reset-default-password/${id}`, value)
    .pipe(
      tap((user) => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })   
    );;
  }
  
  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post<any>(`${Config.baseUrl}/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  isGranted(permission: string | string[]) {
    if (permission == undefined) return true;
    if (permission!.length == 0) return true;

    if (Array.isArray(permission)) {
      return permission.some((p) =>
        this.currentUserValue.permissions?.includes(p)
      );
    } else {
      return this.currentUserValue.permissions?.includes(permission);
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null!);
  }

  me() {
    return this.http.get<any>(`${Config.baseUrlV2}/user/me`).subscribe({
      next: (user) => {
        // update permissions in local storage
        const currentUser = JSON.parse(
          localStorage.getItem('currentUser')!
        ) as User;
        currentUser.permissions = user.permissions;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.currentUserSubject.next(currentUser);
      },
      error: (err) => {
      },
    });
  }
}
