import {
  CustomAdapter,
  CustomDateParserFormatter,
} from './utils/datepicker-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDropdownModule,
  NgbModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgxBootstrapIconsModule,
  archive,
  arrowClockwise,
  bank,
  boxes,
  calendar3,
  caretDownFill,
  caretUpFill,
  chevronDoubleLeft,
  chevronDoubleRight,
  chevronDown,
  chevronLeft,
  chevronRight,
  clipboard2Check,
  currencyDollar,
  currencyExchange,
  dash,
  diagram2,
  exclamationCircle,
  fileText,
  flag,
  gear,
  graphUp,
  grid,
  house,
  key,
  list,
  people,
  personBadge,
  plus,
  shop,
  speedometer,
  truck,
  truckFlatbed,
  map,
  columnsGap,
  pinMap
} from 'ngx-bootstrap-icons';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { EmptyCardModule } from './components/empty-card/empty-card.module';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { GoBackModule } from './components/go-back/go-back.module';
import { GoalsModule } from './pages/goals/goals.module';
import { HomeButtonCardComponent } from './pages/home/components/home-button-card/home-button-card.component';
import { HomeComponent } from './pages/home/home.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { IsGuardedModule } from './components/is-guarded/is-guarded.module';
import { LayoutComponent } from './layout/layout.component';
import { LoadingsComponent } from './pages/loadings/loadings.component';
import { LoginComponent } from './pages/login/login.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { NewOrderComponent } from './pages/order/new-order/new-order.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OrderComponent } from './pages/order/order.component';
import { OthersComponent } from './pages/others/others.component';
import { OthersModule } from './pages/others/others.module';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { PerformancesModule } from './pages/performances/performances.module';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TableModule } from './components/table/table.module';
import { ToastComponent } from './components/toast/toast.component';
import { ToastsModule } from './components/toasts/toasts.module';
import { TransactionsForBillingComponent } from './pages/transactions/transactions-for-billing/transactions-for-billing.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { ClustersModule } from './pages/clusters/clusters.module';
import { OrderModule } from './pages/order/order.module';
import { ToursModule } from './pages/tours/tours.module';
import { CountriesModule } from './pages/countries/countries.module';
import { FirstLoginPasswordChangeComponent } from './pages/first-login-password-change/first-login-password-change.component';

registerLocaleData(localeFr);

const icons = {
  archive,
  graphUp,
  house,
  calendar3,
  clipboard2Check,
  truck,
  truckFlatbed,
  dash,
  diagram2,
  plus,
  boxes,
  exclamationCircle,
  arrowClockwise,
  people,
  shop,
  key,
  personBadge,
  speedometer,
  caretUpFill,
  caretDownFill,
  gear,
  currencyDollar,
  fileText,
  currencyExchange,
  chevronRight,
  chevronLeft,
  chevronDown,
  chevronDoubleLeft,
  chevronDoubleRight,
  flag,
  grid,
  bank,
  list,
  map,
  columnsGap,
  pinMap
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LayoutComponent,
    HomeButtonCardComponent,
    LoadingsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PasswordStrengthComponent,
    NavLinkComponent,
    TransactionsForBillingComponent,
    ToastComponent,
    OthersComponent,
    FirstLoginPasswordChangeComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClustersModule,
    CountriesModule,
    EmptyCardModule,
    FormsModule,
    GoalsModule,
    GoBackModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModule,
    NgbToastModule,
    NgxBootstrapIconsModule.pick(icons),
    NgxSpinnerModule,
    OrderModule,
    OthersModule,
    ToursModule,
    PerformancesModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    IsGuardedModule,
    TableModule,
    ToastsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
