import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionColumnDef, ActionEventType, ColumnDef } from 'src/app/utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() columnDefs!: ColumnDef[];
  @Input() tableData!: any[];
  @Input() tableHeadBgColor: string = '';
  @Input() tableHeigh: string = '';
  @Input() cellTextStyle: string[] = ['text-info', 'fw-bold'];
  @Output() actionInvoqueFunctionEvent = new EventEmitter<ActionEventType>();
  @Output() onClickCellValueEvent = new EventEmitter<
    string | number | boolean
  >();

  //cellTextStyle: any = ['text-info', 'fw-bold'];
  constructor() {}

  ngOnInit(): void {}

  invoqueActionsFunction(event: ActionEventType) {
    this.actionInvoqueFunctionEvent.emit(event);
  }

  clickCellValueEvent(event: any) {
    this.onClickCellValueEvent.emit(event);
  }

  getCellClass(column: ColumnDef): string {
    // const cellTextStyle = column.cellTextStyle?.length
    //   ? column.cellTextStyle
    //   : this.cellTextStyle;
    const classes = column.positionClass.concat(this.cellTextStyle);
    return classes.join(' ');
  }
}
