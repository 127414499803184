<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex my-1">
    <app-go-back></app-go-back>
    <h1 class="h2 text-info">Chargements camions MT</h1>
  </div>
  <div class="btn-toolbar mb-2 mb-md-0 my-1">
    <div class="row">
      <div class="col-sm-6 my-1">
        <select
          [formControl]="sectorFilterControl"
          class="form-select me-2"
          aria-label="Choisir un secteur"
        >
          <option value="" selected disabled>Choisir un secteur</option>
          <option *ngFor="let sector of sectors" [value]="sector.id">
            {{ sector.label }}
          </option>
        </select>
      </div>
      <div class="col-sm-6 my-2">
        <form class="row row-cols-sm-auto">
          <div class="col-12">
            <div class="input-group">
              <input
                ngb-dp-today
                [formControl]="dateFilterControl"
                class="form-control"
                placeholder="dd/mm/yyyy"
                ngbDatepicker
                #d="ngbDatepicker"
              />
              <button
                class="btn btn-outline-secondary calendar bg-info"
                (click)="d.toggle()"
                type="button"
              >
                <i-bs name="calendar3" class="text-white"></i-bs>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row mb-3">
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{
                getAmountTotal()
                  | currency : "XOF" : "symbol" : "1.0-2"
              }}
            </h3>
            <p class="mb-0 fw-bold">CA Total</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{ numberBoxPerTruck }}
              <small class="text-info fw-light"> Casiers</small>
            </h3>
            <p class="mb-0 fw-bold">Capacité camion</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3
              class="{{
                getNeededBoxQuantity() > numberBoxPerTruck
                  ? 'text-danger'
                  : 'text-success'
              }}"
            >
              {{ getNeededBoxQuantity()
              }}<small
                class="fw-light {{
                  getNeededBoxQuantity() > numberBoxPerTruck
                    ? 'text-danger'
                    : 'text-success'
                }}"
              >
                {{ getNeededBoxQuantity() > 1 ? "Casiers" : "Casier" }}</small
              >
            </h3>
            <p class="mb-0 fw-bold">Casiers à charger</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3
              class="{{
                getNeededBoxQuantity() > numberBoxPerTruck
                  ? 'text-danger'
                  : 'text-success'
              }}"
            >
              {{ getNeededCoolerQuantity()
              }}<small
                class="fw-light {{
                  getNeededBoxQuantity() > numberBoxPerTruck
                    ? 'text-danger'
                    : 'text-success'
                }}"
              >
                {{
                  getNeededCoolerQuantity() > 1 ? "Glacières" : "Glacière"
                }}</small
              >
            </h3>
            <p class="mb-0 fw-bold">Glacières à charger</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template [ngIf]="sectorFilterControl.value" [ngIfElse]="selectSectorBlock">
  <div class="row">
    <div class="col">
      <form
        [formGroup]="forecastForm"
        (ngSubmit)="onSubmit()"
        *ngIf="!loadingForecast && editedForecasts.length"
      >
        <table class="table align-middle mb-5 table-responsive">
          <thead class="table-light">
            <tr class="rounded">
              <th scope="col">#</th>
              <th scope="col">Produits</th>
              <th scope="col" class="text-center">Quantité demandée</th>
              <th scope="col" class="text-center">Accessoires</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let forecast of editedForecasts; trackBy: trackForecast"
            >
              <th scope="row" class="fw-normal col-2">#{{ forecast.id }}</th>
              <td class="align-middle fw-bold text-info col-2">
                {{ forecast.product_sku_sage }}
              </td>
              <td class="text-center text-center col-3">
                <div class="row">
                  <div class="col-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-info text-white fw-bold"
                      (click)="decrementQuantity(forecast)"
                    >
                      <i-bs name="dash" class="text-white"></i-bs>
                    </button>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      (keydown)="($event.keyCode !== 69)"
                      (keyup)="onKey($event, forecast)"
                      value="{{ forecast.asked }}"
                    />
                  </div>
                  <div class="col-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-info text-white fw-bold"
                      (click)="incrementQuantity(forecast)"
                    >
                      <i-bs name="plus" class="text-white"></i-bs>
                    </button>
                  </div>
                </div>
              </td>
              <td class="fw-bold text-center">
                <span class="badge rounded-pill bg-primary accessories">
                  {{ accessoriesColumnText(forecast) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <p class="placeholder-glow" *ngIf="loadingForecast">
        <span class="placeholder col-12 placeholder-sm"></span>
        <span class="placeholder col-12 placeholder-sm"></span>
        <span class="placeholder col-12 placeholder-sm"></span>
        <span class="placeholder col-12 placeholder-sm"></span>
        <span class="placeholder col-12 placeholder-sm"></span>
      </p>
      <div
        class="card text-center mx-auto my-auto"
        style="width: 24rem"
        *ngIf="!loadingForecast && !editedForecasts.length"
      >
        <div class="card-body">
          <i-bs
            name="exclamation-circle"
            class="text-info"
            width="100"
            height="100"
          >
          </i-bs>
          <h5 class="card-title mt-4">Pas de prévisions ou chargements</h5>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isInThePast()"
    class="bottom-bar shadow-sm fixed-bottom col-md-9 ms-sm-auto col-lg-10 px-md-4"
  >
    <div class="justify-content-end mb-md-0 mx-1 d-flex">
      <div class="d-flex flex-wrap flex-md-nowrap">
        <div class="row">
          <div class="col" *ngIf="isDirty() && !isInThePast()">
            <div class="mx-auto">
              <div class="vstack gap-9">
                <button
                  type="button"
                  (click)="onSubmit()"
                  class="btn btn-warning text-white"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
          <div
            class="col"
            *ngIf="
              editedForecasts.length > 0 &&
              isValidable() &&
              !isDirty() &&
              !isInThePast()
            "
          >
            <div class="mx-auto">
              <div class="vstack gap-9">
                <button
                  type="button"
                  (click)="onValidate()"
                  class="btn btn-info text-white"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
          <div
            class="col"
            *ngIf="
              editedForecasts.length > 0 &&
              !isValidable() &&
              !isInThePast() &&
              !isDirty()
            "
          >
            <div class="mx-auto">
              <div class="vstack gap-9">
                <button
                  type="button"
                  (click)="onInvalidate()"
                  class="btn btn-danger text-white"
                >
                  Invalider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectSectorBlock>
  <app-empty-card
    [message]="'Veuillez sélectionner un secteur'"
    [icon]="'exclamation-circle'"
    [iconColorClass]="'text-danger'"
  ></app-empty-card>
</ng-template>
