import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import Config from '../utils/config';
import { ConfigKey, formatMapData, IMapFilter } from '../utils/map.util';
import { Router } from '@angular/router';
import { PointOfSale } from '../models/point-of-sale';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private nvaigationParams!: { resource: ConfigKey };
  constructor(private httpClient: HttpClient) { }

  getCoordinates(
    urlSegment: string,
    resource: ConfigKey,
    filter: IMapFilter = {
      sector_ids: [],
      zone_ids: [],
      country_ids: [],
      zone_supervisor_ids: [],
      region_commercial_ids: [],
    }
  ) {
    const coordinates: any = {
      type: 'FeatureCollection',
      name: resource,
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [],
    };
    let payload = {};
    switch(resource) {
      case 'countries':
        payload = { country_ids: filter.country_ids};
        break;
        case 'region_commercials':
        payload = { region_commercial_ids: filter.region_commercial_ids};
        break;
        case 'zone_supervisors':
        payload = {zone_supervisor_ids: filter.zone_supervisor_ids};
        break;
        case 'sectors':
        payload = {sector_ids: filter.sector_ids};
        break;
        case 'zones':
        payload = {zone_ids: filter.zone_ids};
        break;
    }
    return this.httpClient
      .post<{ coordinates: any[] }>(`${Config.baseUrlV2}/${urlSegment}`, payload)
      .pipe(
        map((data: { coordinates: any[] }) => formatMapData(data, coordinates))
      );
  }

  getPointsOfSaleCoordinates(filterIds: IMapFilter) {
    return this.httpClient
      .post<{ coordinates: Partial<PointOfSale>[] }>(
        `${Config.baseUrlV2}/coordinates_point_of_sales`,
        filterIds
      )
  }

  getNavigationParams() {
    return localStorage.getItem('mapNavigationParams');
  }

  setNavigationParams(mapNavigationParams: { resource: ConfigKey }) {
    localStorage.setItem('mapNavigationParams', JSON.stringify(mapNavigationParams));
  }

  redirectToMap(router: Router, resource: ConfigKey) {
    const urlTree = router.createUrlTree(['/maps']);
    const url = router.serializeUrl(urlTree);
    this.setNavigationParams({ resource });
    window.open(url, '_blank',);
  }
}
